import axios from "axios";

import { API_URL } from "@variables";

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export const getCSRFCookie = () => {
  return new Promise((resolve) => {
    api.get("sanctum/csrf-cookie").then(resolve);
  });
};
