export default [
  {
    path: "/statistics",
    name: "stats",
    component: () => import("@/views/stats.vue"),
    meta: {
      pageTitle: "Statistiche",
      breadcrumb: [
        {
          text: "Impostazioni",
          active: true,
        },
        {
          text: "Statistiche",
          active: true,
        },
      ],
    },
  },
  {
    path:"/statistics/stand/:id",
    name: "Statistics-Single-Stand",
    component: () => import("@/views/statistics/statistics-single-stand/single-stand.vue"),
    meta: {
      pageTitle: "Nome dello Stand",
      breadcrumb: [
        {
          text: "Statistiche",
          active: true,
        },
        {
          text: "Dashboard",
          to: "/statistics",
          active: false,
        },
      ],
    }
  }
]