export default [
  {
    path: "/meeting_points",
    name: "ListPoints",
    component: () => import("@/views/meeting-point/meeting-points-list.vue"),
    meta: {
      pageTitle: "Meeting Points",
      breadcrumb: [
        {
          text: "Fiera Virtuale",
          active: true,
        },
        {
          text: "Lista Meeting Points",
          active: true,
        }
      ]
    },
  },
  {
    path: "/meeting_points/:id",
    name: "Single-Point",
    component: () => import("@/views/meeting-point/single-meeting-point.vue"),
    meta: {
      pageTitle: "Meeting Points",
      breadcrumb: [
        {
          text: "Fiera Virtuale",
          active: true,
        },
        {
          text: "Lista Meeting Points",
          to: '/meeting_points',
          active: false,
        },
        {
          text: "Modifica",
          active: true
        }
      ]
    },
  },
];