import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import stand from "./routes/stand";
import meetingpoints from "./routes/meeting-point";
import statistics from "./routes/statistics";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...stand,
    ...meetingpoints,
    ...statistics,
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register/:code",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        auth: false,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/totem",
      name: "totem",
      component: () => import("@/views/totem/totem.vue"),
      meta: {
        pageTitle: "Totem",
        breadcrumb: [
          {
            text: "Fiera Virtuale",
            active: true,
          },
          {
            text: "Totem",
            active: true,
          },
        ],
      },
    },
    {
      path: "/press-room",
      name: "press-room",
      component: () => import("@/views/press-room.vue"),
      meta: {
        pageTitle: "Press Room",
        breadcrumb: [
          {
            text: "Fiera Virtuale",
            active: true,
          },
          {
            text: "Press Room",
            active: true,
          },
        ],
      },
    },
    {
      path: "/conference",
      name: "conference",
      component: () => import("@/views/conference/conference.vue"),
      meta: {
        pageTitle: "Conference",
        breadcrumb: [
          {
            text: "Fiera Virtuale",
            active: true,
          },
          {
            text: "Conference",
            active: true,
          },
        ],
      },
    },
    {
      path: "/personalizations",
      name: "personalizations",
      component: () => import("@/views/personalization/personalizations.vue"),
      meta: {
        pageTitle: "Personalizzazioni",
        breadcrumb: [
          {
            text: "Impostazioni",
            active: true,
          },
          {
            text: "Personalizzazioni",
            active: true,
          },
        ],
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/notification/notifications.vue"),
      meta: {
        pageTitle: "Notifiche",
        breadcrumb: [
          {
            text: "Impostazioni",
            active: true,
          },
          {
            text: "Notifiche",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/visitors.vue"),
      meta: {
        pageTitle: "Visitatori",
        breadcrumb: [
          {
            text: "Fiera Virtuale",
            active: true,
          },
          {
            text: "Visitatori",
            active: true,
          },
        ],
      },
    },
    {
      path: "/editors",
      name: "editors",
      component: () => import("@/views/users/editors.vue"),
      meta: {
        pageTitle: "Editori",
        breadcrumb: [
          {
            text: "Impostazioni",
            active: true,
          },
          {
            text: "Editori",
            active: true,
          },
        ],
      },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/support.vue"),
      meta: {
        pageTitle: "Supporto",
        breadcrumb: [
          {
            text: "Impostazioni",
            active: true,
          },
          {
            text: "Supporto",
            active: true,
          },
        ],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/settings.vue"),
      meta: {
        pageTitle: "Settaggi",
        breadcrumb: [
          {
            text: "Impostazioni",
            active: true,
          },
          {
            text: "Settaggi",
            active: true,
          },
        ],
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
