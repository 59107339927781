import { api, getCSRFCookie } from "./index";

export const login = async (email, password, remember) => {
  await getCSRFCookie();
  return new Promise((resolve, reject) => {
    api
      .post("login", { email, password, remember })
      .then(({ data }) => resolve(data))
      .catch(({ response: { data } }) => reject(data));
  });
};

export const logout = async () => {
  await getCSRFCookie();
  return new Promise((resolve, reject) => {
    api
      .post("logout")
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const user = () => {
  return new Promise((resolve, reject) => {
    api
      .get("user")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
